import {
  Box,
  Button,
  CloseButton,
  Container,
  ScaleFade,
  Stack,
  Text,
  useDisclosure,
  Link,
} from '@chakra-ui/react'

export default function Banner() {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

  return (
    <ScaleFade in={isOpen} unmountOnExit>
      <Box>
        <Box bg="blue.100" color="#2D81A5">
          <Container maxW="800px" py={4} position="relative">
            <Stack direction="row" align="center" justify="center" spacing={4}>
              <Text fontWeight="semibold">
                🎉 We have moved !{' '}
                {/* {' '}
                <Link href="https://chess.ie" isExternal>
                  chess.ie
                </Link>{' '} */}
                🎉
              </Text>

              <CloseButton size="sm" onClick={onClose} />
            </Stack>
          </Container>
        </Box>
      </Box>
    </ScaleFade>
  )
}
