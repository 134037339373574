import Page from 'components/Page'

import Hero from './components/Hero'
import Vr from './components/VR'
import Features from './components/Features'
import Puzzle from './components/Puzzle'
import AnimatedPieces from './components/AnimatedPieces'
import Lessons from './components/Lessons'
import Videos from './components/Videos'
import Pricing from './components/Pricing'

export default function Home() {
  return (
    <Page>
      {/* <Hero /> */}
      {/* <Vr />
      <Features />
      <Puzzle /> */}
      {/* <AnimatedPieces /> */}
      {/* <Lessons /> */}
      <Videos />
      {/* <Pricing /> */}
    </Page>
  )
}
