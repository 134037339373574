import { Redirect } from 'react-router-dom'
import useAuthStore from 'store/useAuthStore'

function GuestGuard({ children }) {
  const user = useAuthStore((state) => state.user)
  const redirect = useAuthStore((state) => state.redirect)

  if (user) {
    return <Redirect to={redirect ? redirect : '/schedule'} />
  }

  return children
}

export default GuestGuard
